@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (max-width: 768px) {
  .text-stroke-tic {
    -webkit-text-stroke: 5px #000000;
    text-shadow: 0px 0px 15px #ffffff84, -0px -0px 15px #ffffff84,
      -0px 0px 15px #ffffff84, 0px -0px 15px #ffffff84;
  }
  .text-stroke-tonus {
    -webkit-text-stroke: 5px #defaca;
  }
  .text-stroke-tontu {
    -webkit-text-stroke: 5px #00816d;
  }
  .text-stroke-skydda {
    -webkit-text-stroke: 5px #d31344;
  }
  .textpadding {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .text-stroke-tic {
    -webkit-text-stroke: 5px #000000;
    text-shadow: 0px 0px 15px #ffffff84, -0px -0px 15px #ffffff84,
      -0px 0px 15px #ffffff84, 0px -0px 15px #ffffff84;
  }
  .text-stroke-tonus {
    -webkit-text-stroke: 5px #defaca;
  }
  .text-stroke-tontu {
    -webkit-text-stroke: 5px #00816d;
  }
  .text-stroke-skydda {
    -webkit-text-stroke: 5px #d31344;
  }
  .textpadding {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .text-stroke-tic {
    -webkit-text-stroke: 8px #000000;
    text-shadow: 0px 0px 15px #ffffff84, -0px -0px 15px #ffffff84,
      -0px 0px 15px #ffffff84, 0px -0px 15px #ffffff84;
  }
  .text-stroke-tonus {
    -webkit-text-stroke: 8px #defaca;
  }
  .text-stroke-tontu {
    -webkit-text-stroke: 8px #00816d;
  }
  .text-stroke-skydda {
    -webkit-text-stroke: 8px #d31344;
  }
  .textpadding {
    padding-top: 20vh;
  }
}

.dropshadow {
  filter: drop-shadow(-15px -15px 20px rgb(255, 255, 255))
    drop-shadow(15px 15px 25px rgba(204, 204, 204, 0.53));
}

.dropshadow2 {
  filter: drop-shadow(-15px -15px 20px rgb(255, 255, 255))
    drop-shadow(15px 15px 20px rgba(187, 187, 187, 0.53));
}

.tontutext {
  font-family: Arial Rounded MT Bold, Helvetica Rounded, Arial, sans-serif;
}

.tontutext2 {
  font-family: "Comfortaa", cursive;
}

html {
  scroll-behavior: smooth;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blur {
  filter: blur(1.5rem);
}

.page {
  display: none;
}

#loading {
  height: 100vh;
  width: 100vw;
  font-family: Roboto, Arial;
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
}
